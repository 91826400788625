import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

const MobileMenu = ({ menuOpen, MenuItems, onClick }) => {
  return (
    <StyledMenu open={menuOpen}>
      <ul>
        {MenuItems.map(navItem => (
          <Link key={navItem.id} to={navItem.id} onClick={onClick}>
            {navItem.title}
          </Link>
        ))}
      </ul>
    </StyledMenu>
  );
};

export default MobileMenu;

const StyledMenu = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #111111;
  background: #000000df;
  height: 100vh;
  text-align: center;
  padding: 2rem;
  position: fixed;
  top: 0;
  right: 0;
  transition: transform 0.3s ease-in-out;
  transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(100%)')};

  @media (max-width: ${props => props.theme.screen.xs}) {
    width: 100%;
  }

  ul {
    list-style: none;
    margin: 0;
    display: flex;
    flex-direction: column;
    padding-top: 8px;
    padding-bottom: 16px;
    padding-inline-start: 0;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
  a {
    font-size: 2rem;
    text-transform: uppercase;
    padding: 2rem 0;
    font-weight: 300;
    letter-spacing: 0.5rem;
    color: white;
    text-decoration: none;
    transition: color 0.3s linear;
    margin: 0;
    margin-top: 0.75em;

    @media (max-width: ${props => props.theme.screen.xs}) {
      font-size: 1.5rem;
      text-align: center;
    }

    &:hover {
      color: #564f62;
    }
  }
`;
