const theme = {
  font: {
    primary: `'Montserrat'`,
    secondary: `'Montserrat'`,
  },
  font_size: {
    xsmall: 'font-size: 16px; line-height: 32px',
    small: 'font-size: 20px; line-height: 40px',
    regular: 'font-size: 24px; line-height: 32px',
    large: 'font-size: 30px; line-height: 40px',
    larger: 'font-size: 36px; line-height: 48px',
    xlarge: 'font-size: 48px; line-height: 96px',
  },
  color: {
    blue: {
      light: '#48B3FF',
      dark: '#2076B3',
    },
    white: {
      regular: '#FFFFFF',
      dark: '#F6F6F6',
    },
    black: {
      lighter: '#ABA8AF',
      light: '#564F62',
      regular: '#211E26',
    },
    grey: {
      light: '#fbfbfb',
      dark: '#333',
      drakTransparent: '#333333ad',
      regular: '#525052',
    },
    transparent: {
      dark: '#333333ad',
    },
    primary: '#8bd8ed',
  },
  screen: {
    xs: '575px',
    sm: '767px',
    md: '991px',
    lg: '1199px',
  },
};

export default theme;
