import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  overflow: visible;
`;

export const ContentMain = styled.div`
  width: 100%;
  background-color: ${props => props.theme.color.grey.light};
`;

export const ContentContainer = styled.div`
  margin: 0 auto;
  background-color: ${props => props.theme.color.white.regular};

  -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.5);

  @media (min-width: ${props => props.theme.screen.lg}) {
    max-width: 1200px;
  }
`;

export const TextBodyWrapper = styled.div`
  margin: 0 auto;

  @media (max-width: ${props => props.theme.screen.xs}) {
    max-width: 100%;
  }

  @media (min-width: ${props => props.theme.screen.xs}) {
    max-width: 510px;
  }

  @media (min-width: ${props => props.theme.screen.sm}) {
    max-width: 700px;
  }

  @media (min-width: ${props => props.theme.screen.md}) {
    max-width: 900px;
  }
`;

export const Row = styled.div`
  padding-top: 64px;
  padding-bottom: 64px;
`;

export const Section = styled.section`
  margin-top: 64px;
  overflow: visible;

  ${props =>
    props.accent &&
    `background-color: ${
      props.accent === 'secondary'
        ? props.theme.color.white.dark
        : props.theme.color.white.regular
    }`};
`;

export const StyledH1 = styled.h1`
  text-align: center;
  font-weight: 300;

  word-break: break-word;
  ${props => props.theme.font_size.xlarge};

  @media (max-width: ${props => props.theme.screen.xs}) {
    font-size: 32px;
  }
`;
