import styled from 'styled-components';

import { Container } from '@components/global';
import { ReactComponent as BPLogo } from '@images/logo.svg';

export const Nav = styled.nav`
  width: 100%;
  top: 0;
  z-index: 1000;
`;
export const Head = styled.div`
  position: relative;
  width: 100%;
  top: 0;
  z-index: 1000;
`;
export const StyledContainer = styled(Container)`
  display: flex;
  justify-content: center;
  background-color: #333333;
  background-color: ${props => props.theme.color.transparent.dark};
`;

export const NavListWrapper = styled.div`
  ul {
    list-style: none;
    margin: 0;
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: row;

    ${({ mobile }) =>
      mobile &&
      ` flex-direction: column;
        padding-top:8px;
        padding-bottom:16px;

        > ${NavItem} {
          margin: 0;
          margin-top: 0.75em;
        }
      `};
  }
`;

export const NavItem = styled.li`
  margin: 0 0.75em;
  font-family: ${props => props.theme.font.secondary};
  ${props => props.theme.font_size.small};

  a {
    text-decoration: none;
    font-weight: 300;
    color: ${props => props.theme.color.white.regular};
  }
  &.active {
    a {
      font-weight: 500;
    }
  }
`;

export const Brand = styled.div`
  display: flex;
  justify-content: center;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 16px;
  background-color: ${props => props.theme.color.white.regular};
`;

export const StyledLogo = styled(BPLogo)`
  max-height: 50px;
  @media (min-width: ${props => props.theme.screen.xs}) {
    max-height: 50px;
  }

  @media (min-width: ${props => props.theme.screen.sm}) {
    max-height: 70px;
  }

  @media (min-width: ${props => props.theme.screen.md}) {
    max-height: 100px;
  }

  @media (min-width: ${props => props.theme.screen.lg}) {
    max-height: 150px;
  }

  ${props =>
    props.fluid &&
    `
  max-height: 150px !important;
`};
  margin-bottom: 16px;
`;
