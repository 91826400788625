import React, { Component } from 'react';
import { Link } from 'gatsby';
import Scrollspy from 'react-scrollspy';
import Burger from '@components/Burger/Burger';
import MobileMenu from '@components/MobileMenu/MobileMenu';
import {
  Nav,
  NavItem,
  Brand,
  StyledContainer,
  NavListWrapper,
  StyledLogo,
  Head,
} from './style';

const NAV_ITEMS = [
  {
    id: '/',
    title: 'Home',
  },
  {
    id: '/leistungen',
    title: 'Leistungen',
  },
  {
    id: '/unternehmen',
    title: 'Über Uns',
  },
  // {
  //   id: '/referenzen',
  //   title: 'Referenzen',
  // },
  {
    id: '/kontakt',
    title: 'Kontakt',
  },
];

function debounceFunction(fn, ms) {
  let timer;
  return _ => {
    clearTimeout(timer);
    timer = setTimeout(_ => {
      timer = null;
      fn.apply(this, arguments);
    }, ms);
  };
}
class Navbar extends Component {
  state = {
    mobileMenuOpen: false,
    isMobile: false,
  };
  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;
    this.isMobile();
    window.addEventListener('resize', () => {
      this.debounceMobileCheck();
    });
  }
  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener('resize', () => {
      this.debounceMobileCheck();
    });
  }
  isMobile = () => {
    if (this._isMounted) {
      this.setState({ isMobile: window.innerWidth <= 767 });
    }
  };

  debounceMobileCheck = () => {
    debounceFunction(this.isMobile(), 1000);
  };
  toggleMobileMenu = () => {
    this.setState(prevState => ({ mobileMenuOpen: !prevState.mobileMenuOpen }));
  };

  getInternalLink = item => (
    <Link to={`${item.id}`} onClick={this.closeMobileMenu}>
      {item.title}
    </Link>
  );

  getNavList = ({ mobile = false }) => (
    <NavListWrapper mobile={mobile}>
      <Scrollspy
        items={NAV_ITEMS.map(item => item.id)}
        currentClassName="active"
        mobile={mobile}
        offset={-64}
      >
        {NAV_ITEMS.map(navItem => (
          <NavItem key={navItem}>{this.getInternalLink(navItem)}</NavItem>
        ))}
      </Scrollspy>
    </NavListWrapper>
  );
  render() {
    const { mobileMenuOpen } = this.state;
    return (
      <>
        {this.state.isMobile ? (
          <Head>
            <Brand>
              <StyledLogo />
              <div>
                <MobileMenu
                  menuOpen={mobileMenuOpen}
                  MenuItems={NAV_ITEMS}
                  onClick={this.toggleMobileMenu}
                />
                <Burger
                  menuOpen={mobileMenuOpen}
                  onClick={this.toggleMobileMenu}
                />
              </div>
            </Brand>
          </Head>
        ) : (
          <Nav {...this.props}>
            <Brand>
              <StyledLogo />
            </Brand>
            <StyledContainer>{this.getNavList(false)}</StyledContainer>
          </Nav>
        )}
      </>
    );
  }
}

export default Navbar;
